import firebase from 'firebase/app'
import mail from '@/firestore/mail/msg'

export default async function sendEmail(payload) {
  try {
    const { email, data } = payload

    const index1 = mail.findIndex(v => v.id === 3)
    const msg = mail[index1]
    let text = String(msg.text)
    text = String(text).replace(/MAIL_ADDRESS/g, email)
    text = String(text).replace(/JOB_TYPE/g, data.職種名)
    text = String(text).replace(/JOB_PLACE/g, data.勤務地)
    text = String(text).replace(/JOB_CATEGORY/g, data.職種カテゴリー)
    text = String(text).replace(/JOB_STYLE/g, data.雇用形態)

    const mailTo = ['k.suzuki@collect-agency.com', 't.fujibuchi@collect-agency.com']
    mailTo.push(email)
    // // mailTo.push(prote_mail)

    const content = {
      bcc: mailTo,
      message: {
        subject: msg.title,
        text,
      },
      type: 'consumer',
      category: 'apply',
    }
    await firebase.firestore().collection('mail').add(content)
    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: '求人情報お申込み完了のメールが送信されませんでした。',
    }
    return response
  }
}
