<template>
  <div>
    <b-card
      img-height="200px"
    >
      <a>
        <b-card-text class="mb-1">
          <b-row class="tagspace">
            <div
              class="textA outline_"
            >
              {{ item.勤務地 }}
            </div>
            <div
              class="textA outline_ text-primary"
            >
              <b>{{ item.職種カテゴリー }}</b>
            </div>
          </b-row>
        </b-card-text>
      </a>
      <b-card-text>
        <div
          class="title_"
          @click="detail(item.docID)"
        >
          <a>
            <b>{{ item.職種名 }}</b>
          </a>
        </div>
        <b-row class="tagspace">
          <div
            v-if="item.タグ && item.タグ.length > 0"
            class="tag_ outline_"
          >
            {{ item.タグ }}
          </div>
          <div
            v-if="item.タグ_1 && item.タグ_1.length > 0"
            class="tag_ outline_"
          >
            {{ item.タグ_1 }}
          </div>
          <div
            v-if="item.タグ_2 && item.タグ_2.length > 0"
            class="tag_ outline_"
          >
            {{ item.タグ_2 }}
          </div>
          <div
            v-if="item.タグ_3 && item.タグ_3.length > 0"
            class="tag_ outline_"
          >
            {{ item.タグ_3 }}
          </div>
          <div
            v-if="item.タグ_4 && item.タグ_4.length > 0"
            class="tag_ outline_"
          >
            {{ item.タグ_4 }}
          </div>
        </b-row>
        <div class="mt-1">
          <span
            v-if="item['給与（下限）'] !== ''"
            class="title_ mt-1 text-primary text-right"
          >
            <small><b>{{ item.給与種別 }}</b></small> <b>{{ item['給与（下限）'] | localeNumberShow }}円</b>
          </span>
          <span class="title_ text-primary">
            〜
          </span>
          <span
            v-if="item.給与上限 !== ''"
            class="title_ mt-1 text-primary text-right"
          >
            <small><b>{{ item.給与種別 }}</b></small> <b>{{ item.給与上限 | localeNumberShow }}円</b>
          </span>
        </div>
        <div class="textA mt-1">
          {{ item.求人キャッチコピー }}
        </div>
        <hr>
        <div class="textA mt-1">
          {{ item['仕事内容（勤務時間・曜日）'] }}
        </div>
        <div class="textA mt-1">
          {{ item['仕事内容（休暇・休日）'] }}
        </div>
        <div class="textA mt-1">
          {{ item['仕事内容（待遇・福利厚生）'] }}
        </div>
      </b-card-text>
      <div class="text-center">
        <b-row>
          <b-col>
            <b-button
              variant="flat-primary"
              tag="a"
              class="btn-wishlist"
              @click="addFavorite(item)"
            >
              <feather-icon
                v-if="isFavorite"
                icon="HeartIcon"
                fill="currentColor"
                class="mr-50"
              />
              <feather-icon
                v-else
                icon="HeartIcon"
                :fill="item.isFavorite? 'currentColor' : 'none'"
                class="mr-50"
              />
              <span>いいね</span>
            </b-button>
          </b-col>
          <b-col>
            <b-button
              variant="flat-primary"
              tag="a"
              class="btn-cart"
              @click="detail()"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <small>詳細</small>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      v-model="isShowModal"
      title="詳細情報"
      ok-variant="outline-secondary"
      ok-title="閉じる"
      ok-only
      scrollable
      size="lg"
    >
      <b-card-text class="mt-5">
        <span
          class="textM outline_M"
        >
          {{ dataDic.勤務地 }}
        </span>
        <span
          class="textA outline_M ml-1 text-primary"
        >
          <b>{{ dataDic.職種カテゴリー }}</b>
        </span>
      </b-card-text>
      <b-card-text>
        <div class="title_">
          <b>{{ dataDic.職種名 }}</b>
        </div>
        <div class="">
          <span class="tag_ outline_M">
            {{ dataDic.タグ }}
          </span>
          <span class="tag_ outline_M">
            {{ dataDic.タグ_1 }}
          </span>
          <span class="tag_ outline_M">
            {{ dataDic.タグ_2 }}
          </span>
          <span class="tag_ outline_M">
            {{ dataDic.タグ_3 }}
          </span>
          <span class="tag_ outline_M">
            {{ dataDic.タグ_4 }}
          </span>
        </div>
        <div class="mt-1">
          <span
            v-if="dataDic['給与（下限）'] !== ''"
            class="title_ mt-1 text-primary text-right"
          >
            <small><b>{{ dataDic.給与種別 }}</b></small> <b>{{ dataDic['給与（下限）'] | localeNumberShow }}円</b>
          </span>
          <span class="title_ text-primary">
            〜
          </span>
          <span
            v-if="dataDic.給与上限 !== ''"
            class="title_ mt-1 text-primary text-right"
          >
            <small><b>{{ dataDic.給与種別 }}</b></small> <b>{{ dataDic.給与上限 | localeNumberShow }}円</b>
          </span>
        </div>
        <div class="textA mt-1">
          {{ dataDic.求人キャッチコピー }}
        </div>
        <hr>
        <div class="textC mt-1">
          【求める人材】{{ dataDic['仕事内容（求める人材）'] }}
        </div>
        <div class="tag_ mt-1">
          【雇用形態】{{ dataDic['雇用形態'] }}
        </div>
        <div class="textA mt-1">
          【勤務時間・曜日】{{ dataDic['仕事内容（勤務時間・曜日）'] }}
        </div>
        <div class="textA mt-1">
          【お仕事内容】{{ dataDic['仕事内容（仕事内容）'] }}
        </div>
        <div class="textA mt-1">
          【アピールポイント】{{ dataDic['仕事内容（アピールポイント）'] }}
        </div>
        <div class="textA mt-1">
          【休暇・休日】{{ dataDic['仕事内容（休暇・休日）'] }}
        </div>
        <div class="textA mt-1">
          【待遇・福利厚生】{{ dataDic['仕事内容（待遇・福利厚生）'] }}
        </div>
        <div class="textA mt-1">
          【勤務地】{{ dataDic['仕事内容（勤務地）'] }}
        </div>
        <div class="textA mt-1">
          【アクセス】{{ dataDic['仕事内容（アクセス）'] }}
        </div>
        <div class="textZ mt-1">
          【その他】{{ dataDic['仕事内容（その他）'] }}
        </div>
      </b-card-text>
      <b-row class="mt-2">
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="apply"
          >
            応募する
            <b-spinner
              v-if="status === 2"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BRow, BCol, BButton, BCardText, BModal, BSpinner,
} from 'bootstrap-vue'
import getJobsDataList from '@/firestore/jobs/getList'
// import vSelect from 'vue-select'
import firebase from 'firebase/app'
import addData from '@/firestore/apply/create'
import sendEmail from '@/firestore/jobs/mail'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    BModal,
    BSpinner,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  filters: {
    localeNumberShow(val) {
      try {
        return val.toLocaleString()
      } catch {
        // something
        return ''
      }
    },
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isShowModal: false,
      dataDic: {},
      status: '',
      status2: '',
      uid: '',
      mail: '',
      isFavorite: false,
    }
  },
  computed: {
    ...mapGetters('jobSeekerBasic', ['favoriteList']),
  },
  mounted() {
    if (typeof this.item.isFavorite !== 'undefined') {
      this.isFavorite = this.item.isFavorite
    } else {
      const index = this.favoriteList.findIndex(v => v.docID === this.item.docID)
      // window.console.log('job', index, this.item.docID)
      index !== -1 ? this.isFavorite = true : this.isFavorite = false
    }
  },
  methods: {
    detail() {
      // this.$router.push({ path: '/lesson', query: { id } })
      // window.console.log('🏎✨️', id)
      this.dataDic = this.item
      this.isShowModal = true
    },
    show() {
      window.console.log('🐶')
    },
    async apply() {
      // storeから取得するように変更
      const user = await firebase.auth().currentUser
      this.uid = user.uid
      this.mail = user.email
      if (user === null) {
        window.console.log('ログインしてください')
        this.$router.push({ name: 'login' })
      } else {
        const data = {
          collection: 'apply',
          uid: this.uid,
          mail: this.mail,
          data: this.dataDic,
        }
        // window.console.log('🌺', this.dataDic)
        const response = await addData(data)
        if (response.status === 'success') {
          // window.console.log('success', response.status)
          this.send()
        } else {
          window.console.log('error', response.msg)
        }
      }
    },
    async send() {
      const data = {
        collection: 'mail',
        email: this.mail,
        data: this.dataDic,
      }
      const response = await sendEmail(data)
      if (response.status === 'success') {
        // window.console.log('success', response.status)
        this.success()
      } else {
        window.console.log('error', response.msg)
      }
    },
    async search() {
      this.dataList = []
      this.status2 = 2
      const data = {
        collection: 'jobs',
        place: this.selectedA,
      }
      const response = await getJobsDataList(data)
      if (response.status === 'success') {
        // window.console.log('success', response.status)
        this.dataList = response.data
        this.status2 = 1
      } else {
        window.console.log('error', response.msg)
      }
    },
    showBtn() {
      this.$ref.sbchild.$emit('success')
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'お申込みが完了しました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.isShowModal = false
        this.status = 1
      })
    },
    addFavorite(item) {
      if (this.isFavorite) {
        this.$store.dispatch('jobSeekerBasic/deleteFromFavoriteList', item)
      } else {
        this.$store.dispatch('jobSeekerBasic/addFavoriteList', item)
      }
      this.isFavorite = !this.isFavorite
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.title_ {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0% 0 1% 0;
}
.textA {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0 2% 2% 0;
}
.textZ {
  font-size: 0.8rem;
  font-weight: 500;
  color: rgb(163, 164, 165);
}
.textC {
  font-size: 0.9rem;
  font-weight: 800;
}
.textM {
  font-size: 0.5rem;
  font-weight: 500;
}
.title_:hover {
  color: rgb(65, 56, 155);
}
.outline_ {
  outline: 1px solid rgb(134, 134, 134);
  padding: 0 0.5%;
  border-radius: 4px;
}
.outline_M {
  outline: 1px solid rgb(134, 134, 134);
  padding: 0.5%;
  border-radius: 5px;
}
.tag_ {
  font-size: 0.7rem;
  /* margin: 1% 1% 0 0; */
  margin: 0 2% 2% 0;
}
.tagspace {
  display: flex;
  margin: 0 0 0 1%;
}
</style>
