<template>
  <div>
    <b-tabs align="right">
      <b-tab>
        <template #title>
          <feather-icon icon="SearchIcon" />
          <span>検索</span>
        </template>
        <h2>求人情報</h2>
        <div class="mb-1">
          ご希望の都道府県名を選択して検索して下さい。
        </div>
        <b-card
          title=""
        >
          <b-row>
            <b-col lg="4">
              <div class="">
                <div class="">
                  都道府県
                </div>
                <v-select
                  v-model="selectedA"
                  :options="choiceA"
                />
              </div>
            </b-col>
            <b-col lg="4">
              <div class="">
                <div class="">
                  市区町村
                </div>
                <v-select
                  v-model="selectedB"
                  :options="choiceB"
                />
              </div>
            </b-col>
            <b-col lg="4">
              <div class="">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2"
                  @click="search"
                >
                  検索する
                  <b-spinner
                    v-if="status2 === 2"
                    small
                  />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <h2>検索結果：{{ dataList.length }} 件</h2>
        <b-row>
          <b-col
            v-for="(item,index) in dataList"
            :key="index"
            lg="4"
          >
            <Job :item="item" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="HeartIcon" />
          <span>
            お気に入りリスト
            <b-badge
              v-if="favoriteList.length > 0"
              pill
              variant="light-danger"
            >
              {{ favoriteList.length }}
            </b-badge>
          </span>
        </template>
        <div v-if="favoriteList.length === 0">
          <h3 class="mt-3">
            お気に入りリストに登録されているデータはありません。
          </h3>
        </div>
        <div
          v-else
        >
          <h2>お気に入り：{{ favoriteList.length }} 件</h2>
          <!-- {{ favoriteList }} -->
          <b-row>
            <b-col
              v-for="(item,index) in favoriteList"
              :key="index"
              lg="4"
            >
              <Job :item="item" />
            </b-col>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BRow, BCol, BButton, BSpinner, BTabs, BTab, BBadge,
} from 'bootstrap-vue'
import getJobsDataList from '@/firestore/jobs/getList'
import vSelect from 'vue-select'
import firebase from 'firebase/app'
import addData from '@/firestore/apply/create'
import sendEmail from '@/firestore/jobs/mail'
import Job from '@/components/basic/jobs/Job.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    // BCardText,
    // BModal,
    BSpinner,
    BTabs,
    BTab,
    BBadge,
    vSelect,
    Job,
  },
  directives: {
    Ripple,
  },
  filters: {
    localeNumberShow(val) {
      try {
        return val.toLocaleString()
      } catch {
        // something
        return ''
      }
    },
  },
  props: {
  },
  data() {
    return {
      dataList: [],
      isShowModal: false,
      dataDic: {},
      status: '',
      status2: '',
      selectedA: [],
      selectedB: [],
      choiceA: [
        '東京都',
        '神奈川県',
        '千葉県',
        '岐阜県',
        '京都府',
        '茨城県',
        '滋賀県',
        '福岡県',
      ],
      choiceB: [
        '-',
      ],
      uid: '',
      mail: '',
      place: [
        {
          label: '東京都',
          placeList: ['千代田区', '港区', '荒川区', '渋谷区', '新宿区', '杉並区', '世田谷区', '足立区', '台東区', '大田区', '中央区', '中野区', '板橋区', '品川区', '豊島区', '北区', '墨田区', '目黒区', '江戸川区', '江東区', '葛飾区', '国分寺市', '国立市', '小金井市', '小平市', '昭島市', '多摩市', '町田市', '東村山市', '日野市', '八王子市', '武蔵野市', '立川市'],
        },
        {
          label: '神奈川県',
          placeList: ['横浜市', '川崎市', '中郡', '平塚市', '茅ヶ崎市', '藤沢市', '秦野市', '伊勢原市'],
        },
        {
          label: '千葉県',
          placeList: ['船橋市', '千葉市', '八千代市', '市川市'],
        },
        {
          label: '岐阜県',
          placeList: ['瑞穂市', '大垣市', '岐阜市'],
        },
        {
          label: '京都府',
          placeList: ['京都市'],
        },
        {
          label: '茨城県',
          placeList: ['牛久市', 'ひたちなか市', '取手市', '笠間市', '那珂市', '常陸大宮市', '常陸太田市', '土浦市', '高萩市', '守谷市', 'つくばみらい市', '日立市', '水戸市', 'つくば市'],
        },
        {
          label: '滋賀県',
          placeList: ['守山市', '湖南市', '草津市', '栗東市', '野洲市'],
        },
        {
          label: '福岡県',
          placeList: ['福岡市'],
        },
      ],
    }
  },
  computed: {
    ...mapGetters('jobSeekerBasic', ['favoriteList']),
  },
  watch: {
    selectedA: {
      handler() {
        this.selectedB = []
        const target = this.place.find(v => v.label === this.selectedA)
        if (target) this.choiceB = target.placeList
      },
      immediate: true,
    },
  },
  mounted() {
    this.dataList = []
    this.selectedA = '東京都'
    this.selectedB = '杉並区'
    this.search()
  },
  methods: {
    detail(id) {
      // this.$router.push({ path: '/lesson', query: { id } })
      // window.console.log('🏎✨️', id)
      this.dataDic = this.dataList.find(v => v.docID === id)
      this.isShowModal = true
    },
    show() {
      window.console.log('🐶')
    },
    async apply() {
      const user = await firebase.auth().currentUser
      this.uid = user.uid
      this.mail = user.email
      if (user === null) {
        window.console.log('ログインしてください')
        this.$router.push({ name: 'login' })
      } else {
        const data = {
          collection: 'apply',
          uid: this.uid,
          mail: this.mail,
          data: this.dataDic,
        }
        // window.console.log('🌺', this.dataDic)
        const response = await addData(data)
        if (response.status === 'success') {
          // window.console.log('success', response.status)
          this.send()
        } else {
          window.console.log('error', response.msg)
        }
      }
    },
    async send() {
      const data = {
        collection: 'mail',
        email: this.mail,
        data: this.dataDic,
      }
      const response = await sendEmail(data)
      if (response.status === 'success') {
        // window.console.log('success', response.status)
        this.success()
      } else {
        window.console.log('error', response.msg)
      }
    },
    async search() {
      this.dataList = []
      this.status2 = 2
      const data = {
        collection: 'jobs',
        place: this.selectedB,
      }
      const response = await getJobsDataList(data)
      if (response.status === 'success') {
        // window.console.log('success', response.status)
        this.dataList = response.data
        this.status2 = 1
      } else {
        window.console.log('error', response.msg)
      }
    },
    showBtn() {
      this.$ref.sbchild.$emit('success')
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'お申込みが完了しました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.isShowModal = false
        this.status = 1
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.title_ {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0% 0 1% 0;
}
.textA {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0 2% 2% 0;
}
.textZ {
  font-size: 0.8rem;
  font-weight: 500;
  color: rgb(163, 164, 165);
}
.textC {
  font-size: 0.9rem;
  font-weight: 800;
}
.textM {
  font-size: 0.5rem;
  font-weight: 500;
}
.title_:hover {
  color: rgb(65, 56, 155);
}
.outline_ {
  outline: 1px solid rgb(134, 134, 134);
  padding: 0.5%;
  border-radius: 5px;
}
.outline_M {
  outline: 1px solid rgb(134, 134, 134);
  padding: 0.5%;
  border-radius: 5px;
}
.tag_ {
  font-size: 0.7rem;
  /* margin: 1% 1% 0 0; */
  margin: 0 2% 2% 0;
}
.tagspace {
  display: flex;
  margin: 0 0 0 1%;
}
</style>
