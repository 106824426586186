import firebase from 'firebase/app'

export default async function addData(payload) {
  const {
    collection, uid, mail, data,
  } = payload
  // window.console.log('🎁', data)
  try {
    const promise = await firebase.firestore().collection(collection).doc()

    data.docID = promise.id
    data.insertTime = new Date()
    data.uid = uid
    data.mail = mail

    await firebase.firestore().collection(collection).doc(promise.id).set(data, { merge: true })

    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
